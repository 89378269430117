.BackupButton {
    border: none;
    color: white;
    border-radius: 12px;
    opacity: 1;
    transition: 0.3s;
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (orientation: landscape) {
    .BackupButton {
        font-size: 2vw;
    }
}

@media only screen and (orientation: portrait) {
    .BackupButton {
        font-size: 2vh;
    }
}

.BackupButton:disabled {opacity: 0.5}

.BackupButton:hover:enabled {opacity: 0.8}