.App {
    text-align: center;
    margin: 6vh;
    box-shadow: 11px 17px 48px #e5e5e5;
    border-radius : 10px;
    padding : 10px;
}

body {
    background-color: #fbfafc;
}

.App-banner {
    justify-content: center;
    display: flex;
    flex-direction: row;
    max-height: 50vh;
    color: #0f5f54;
    margin-bottom: 2vh;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
}




.App-link {
    color: #0f5f54;
}

.Commands {
    /* margin-top: 10vh; */
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
}

.Toastify__toast-container {
    width: 400px;
}


